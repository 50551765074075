export const scrollToTargetElement = (id) => {
  try {
    const iframe = document.getElementById('psn-widget-content-frame');
    if (!iframe) {
      document.getElementById(`component-${id}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    const pisanoContainer = iframe.contentWindow.document.getElementById('pisano-container');
    if (!pisanoContainer) return;

    const targetElement = pisanoContainer.querySelector(`[id='component-${id}']`);
    if (targetElement) {
      try {
        // find header height because maybe it's changed
        const header = iframe.contentWindow.document.querySelector(
          '#pisano-frame-root .widget-content-container .widget-content-container__header',
        );
        const offset = targetElement.offsetTop - (header ? header.offsetHeight : 0) - 20;
        pisanoContainer.scrollTo({ top: offset, behavior: 'smooth' });
      } catch (e) {
        console.error('scrollToTargetElement', e);
        // targetElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    }
  } catch (e) {
    console.error('scrollToTargetElement', e);
  }
};
