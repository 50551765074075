/*
 *
 * Flow constants
 *
 */

// Saga
export const LOAD_FLOW = 'app/Flow/LOAD_FLOW';
export const LOAD_FLOW_SUCCESS = 'app/Flow/LOAD_FLOW_SUCCESS';
export const LOAD_FLOW_ERROR = 'app/Flow/LOAD_FLOW_ERROR';
export const LOAD_NODE = 'app/Flow/LOAD_NODE';
export const LOAD_NODE_SUCCESS = 'app/Flow/LOAD_NODE_SUCCESS';
export const LOAD_NODE_ERROR = 'app/Flow/LOAD_NODE_ERROR';
export const LOAD_CUSTOMER_SUCCESS = 'app/Flow/LOAD_CUSTOMER_SUCCESS';
export const LOAD_CUSTOMER_ERROR = 'app/Flow/LOAD_CUSTOMER_ERROR';
export const RESET_FLOW = 'app/Flow/RESET_FLOW';
export const SETUP_CURRENT_STATE = 'app/Flow/SETUP_CURRENT_STATE';
export const CHANGE_RESPONSE = 'app/Flow/CHANGE_RESPONSE';
export const SET_QUESTION = 'app/Flow/SET_QUESTION';
export const APPLY_TRIGGER = 'app/Flow/APPLY_TRIGGER';
export const REMOVE_APPLIED_TRIGGER = 'app/Flow/REMOVE_APPLIED_TRIGGER';
export const UPDATE_ELEMENT = 'app/Flow/UPDATE_ELEMENT';
export const NEXT_STATE = 'app/Flow/NEXT_STATE';
export const PREVIOUS_STATE = 'app/Flow/PREVIOUS_STATE';
export const TOGGLE_AGREEMENT_MODAL = 'app/Flow/TOGGLE_AGREEMENT_MODAL';
export const ENQUEUE_FEEDBACK = 'app/Flow/ENQUEUE_FEEDBACK';
export const PROCESS_FEEDBACK_QUEUE = 'app/Flow/PROCESS_FEEDBACK_QUEUE';
export const UPLOAD_FILE = 'app/Flow/UPLOAD_FILE';
export const REMOVE_FILE = 'app/Flow/REMOVE_FILE';
export const SET_PHONE_VERIFICATION_DATA = 'app/Flow/SET_PHONE_VERIFICATION_DATA';
export const SEND_VERIFICATION_TOKEN = 'app/Flow/SEND_VERIFICATION_TOKEN';
export const VERIFY_PHONE_NUMBER = 'app/Flow/VERIFY_PHONE_NUMBER';
export const DO_DYNAMIC_REQUEST = 'app/Flow/DO_DYNAMIC_REQUEST';
export const TOGGLE_DROPDOWN = 'app/Flow/TOGGLE_DROPDOWN';
export const CLEAR_RESPONSES_ON_STATE = 'app/Flow/CLEAR_RESPONSES_ON_STATE';
export const RESET_IDLE_TIMEOUT = 'app/Flow/RESET_IDLE_TIMEOUT';
export const SET_ANIMATIONS_ENABLED = 'app/Flow/SET_ANIMATIONS_ENABLED';
export const SET_DEFAULT_PLACEHOLDER = 'app/Flow/SET_DEFAULT_PLACEHOLDER';
export const SET_FIRST_RESPONSE_TIME = 'app/Flow/SET_FIRST_RESPONSE_TIME';
export const SMS_UNSUBSCRIBE = 'app/Flow/SMS_UNSUBSCRIBE';
export const WHATSAPP_UNSUBSCRIBE = 'app/Flow/WHATSAPP_UNSUBSCRIBE';
export const PUSH_NOTIFICATION_UNSUBSCRIBE = 'app/Flow/PUSH_NOTIFICATION_UNSUBSCRIBE';
export const CUSTOMER_UNSUBSCRIBED = 'app/Flow/CUSTOMER_UNSUBSCRIBED';
export const WHATSAPP_CUSTOMER_UNSUBSCRIBED = 'app/Flow/WHATSAPP_CUSTOMER_UNSUBSCRIBED';
export const PUSH_NOTIFICATION_CUSTOMER_UNSUBSCRIBED = 'app/Flow/PUSH_NOTIFICATION_CUSTOMER_UNSUBSCRIBED';
export const UNSUBSCRIPTION_REQUESTED = 'app/Flow/UNSUBSCRIPTION_REQUESTED';
export const WHATSAPP_UNSUBSCRIPTION_REQUESTED = 'app/Flow/WHATSAPP_UNSUBSCRIPTION_REQUESTED';
export const PUSH_NOTIFICATION_UNSUBSCRIPTION_REQUESTED = 'app/Flow/PUSH_NOTIFICATION_UNSUBSCRIPTION_REQUESTED';
export const SEND_POST_MESSAGE = 'app/Flow/SEND_POST_MESSAGE';
export const SET_FEEDBACK_RESPONSE = 'app/Flow/SET_FEEDBACK_RESPONSE';
export const SET_IS_SUBMITTING = 'app/Flow/SET_IS_SUBMITTING';
export const SET_IS_SUBMIT_ERROR = 'app/Flow/SET_IS_SUBMIT_ERROR';
export const SEND_CHANNEL_QUOTA_LOG = 'app/Flow/SEND_CHANNEL_QUOTA_LOG';
export const SET_PREVENT_MULTIPLE_FEEDBACK = 'app/Flow/SET_PREVENT_MULTIPLE_FEEDBACK';
export const SET_PRELOADED_RESPONSES = 'app/Flow/SET_PRELOADED_RESPONSES';
export const SET_RESPONSES_LOADED = 'app/Flow/SET_RESPONSES_LOADED';

// Others
export const FEEDBACK_QUEUE = 'FEEDBACK_QUEUE';
export const TRIGGER_MAKE_VISIBLE = 'make_visible';
export const TRIGGER_MAKE_INVISIBLE = 'make_invisible';
export const EMAIL_REGEX =
  /^$|^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,8})[\W]*$/; // Null or Valid Email
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const DEFAULT_COUNTRY_CODE = 'tr';
export const FLOW_ANSWERS = 'FLOW/';
export const DEVICE_ID = 'DEVICE_ID';
export const INCOMPLETE_SURVEYS = 'INCOMPLETE_SURVEYS';
export const PISANO_SESSION = 'PISANO_SESSION';
