/*
 *
 * Flow actions
 *
 */

import {
  APPLY_TRIGGER,
  CHANGE_RESPONSE,
  CLEAR_RESPONSES_ON_STATE,
  CUSTOMER_UNSUBSCRIBED,
  WHATSAPP_CUSTOMER_UNSUBSCRIBED,
  PUSH_NOTIFICATION_CUSTOMER_UNSUBSCRIBED,
  DO_DYNAMIC_REQUEST,
  ENQUEUE_FEEDBACK,
  LOAD_CUSTOMER_ERROR,
  LOAD_CUSTOMER_SUCCESS,
  LOAD_FLOW,
  LOAD_FLOW_ERROR,
  LOAD_FLOW_SUCCESS,
  LOAD_NODE,
  LOAD_NODE_ERROR,
  LOAD_NODE_SUCCESS,
  NEXT_STATE,
  PREVIOUS_STATE,
  PROCESS_FEEDBACK_QUEUE,
  REMOVE_APPLIED_TRIGGER,
  REMOVE_FILE,
  RESET_FLOW,
  RESET_IDLE_TIMEOUT,
  SEND_CHANNEL_QUOTA_LOG,
  SEND_POST_MESSAGE,
  SEND_VERIFICATION_TOKEN,
  SETUP_CURRENT_STATE,
  SET_ANIMATIONS_ENABLED,
  SET_DEFAULT_PLACEHOLDER,
  SET_FEEDBACK_RESPONSE,
  SET_FIRST_RESPONSE_TIME,
  SET_IS_SUBMITTING,
  SET_IS_SUBMIT_ERROR,
  SET_PHONE_VERIFICATION_DATA,
  SET_PRELOADED_RESPONSES,
  SET_QUESTION,
  SMS_UNSUBSCRIBE,
  TOGGLE_AGREEMENT_MODAL,
  TOGGLE_DROPDOWN,
  UNSUBSCRIPTION_REQUESTED,
  WHATSAPP_UNSUBSCRIBE,
  WHATSAPP_UNSUBSCRIPTION_REQUESTED,
  PUSH_NOTIFICATION_UNSUBSCRIBE,
  PUSH_NOTIFICATION_UNSUBSCRIPTION_REQUESTED,
  UPDATE_ELEMENT,
  UPLOAD_FILE,
  VERIFY_PHONE_NUMBER,
  SET_PREVENT_MULTIPLE_FEEDBACK,
  SET_RESPONSES_LOADED,
} from './constants';

export function toggleAgreementModal({ questionId }) {
  return {
    type: TOGGLE_AGREEMENT_MODAL,
    payload: { questionId },
  };
}

export function loadFlow(queryParams, customAttributes) {
  return {
    type: LOAD_FLOW,
    payload: { queryParams, customAttributes },
  };
}

export function smsUnsubscribe(queryParams) {
  return {
    type: SMS_UNSUBSCRIBE,
    payload: { queryParams },
  };
}

export function requestUnsubscription(payload) {
  return {
    type: UNSUBSCRIPTION_REQUESTED,
    payload,
  };
}

export function whatsappUnsubscribe(queryParams) {
  return {
    type: WHATSAPP_UNSUBSCRIBE,
    payload: { queryParams },
  };
}

export function whatsappRequestUnsubscription(payload) {
  return {
    type: WHATSAPP_UNSUBSCRIPTION_REQUESTED,
    payload,
  };
}

export function customerUnsubscribed(payload) {
  return {
    type: CUSTOMER_UNSUBSCRIBED,
    payload,
  };
}

export function whatsappCustomerUnsubscribed(payload) {
  return {
    type: WHATSAPP_CUSTOMER_UNSUBSCRIBED,
    payload,
  };
}

export function pushNotificationUnsubscribe(queryParams) {
  return {
    type: PUSH_NOTIFICATION_UNSUBSCRIBE,
    payload: { queryParams },
  };
}

export function pushNotificationRequestUnsubscription(payload) {
  return {
    type: PUSH_NOTIFICATION_UNSUBSCRIPTION_REQUESTED,
    payload,
  };
}

export function pushNotificationCustomerUnsubscribed(payload) {
  return {
    type: PUSH_NOTIFICATION_CUSTOMER_UNSUBSCRIBED,
    payload,
  };
}

export function flowLoaded(payload) {
  return {
    type: LOAD_FLOW_SUCCESS,
    payload,
  };
}

export function flowLoadingError(payload) {
  return {
    type: LOAD_FLOW_ERROR,
    payload,
  };
}

export function customerLoaded(payload) {
  return {
    type: LOAD_CUSTOMER_SUCCESS,
    payload,
  };
}

export function customerLoadingError(payload) {
  return {
    type: LOAD_CUSTOMER_ERROR,
    payload,
  };
}

export function resetFlow() {
  return {
    type: RESET_FLOW,
  };
}

export function loadNode(queryParams) {
  return {
    type: LOAD_NODE,
    payload: { queryParams },
  };
}

export function nodeLoaded(payload) {
  return {
    type: LOAD_NODE_SUCCESS,
    payload,
  };
}

export function nodeLoadingError(payload) {
  return {
    type: LOAD_NODE_ERROR,
    payload,
  };
}

export function setupCurrentState(state) {
  return {
    type: SETUP_CURRENT_STATE,
    payload: state,
  };
}

export function changeResponse({ questionId, ...params }) {
  return {
    type: CHANGE_RESPONSE,
    payload: { questionId, newResponseParams: { ...params } },
  };
}

export function resetIdleTimeout() {
  return {
    type: RESET_IDLE_TIMEOUT,
  };
}

export function setQuestion(payload) {
  return {
    type: SET_QUESTION,
    payload,
  };
}

export function nextState(stateChangeParams) {
  return {
    type: NEXT_STATE,
    payload: { stateChangeParams },
  };
}

export function previousState() {
  return {
    type: PREVIOUS_STATE,
  };
}

export function applyTrigger(payload) {
  return {
    type: APPLY_TRIGGER,
    payload,
  };
}

export function removeAppliedTrigger(payload) {
  return {
    type: REMOVE_APPLIED_TRIGGER,
    payload,
  };
}

export function updateElement(payload) {
  return {
    type: UPDATE_ELEMENT,
    payload,
  };
}

export function uploadFile({ questionId, file }) {
  return {
    type: UPLOAD_FILE,
    payload: { questionId, file },
  };
}

export function removeFile({ questionId }) {
  return {
    type: REMOVE_FILE,
    payload: { questionId },
  };
}

export function doDynamicRequest({ questionId, answer }) {
  return {
    type: DO_DYNAMIC_REQUEST,
    payload: { questionId, answer },
  };
}

export function enqueueFeedback(payload) {
  return {
    type: ENQUEUE_FEEDBACK,
    payload,
  };
}

export function processFeedbackQueue() {
  return {
    type: PROCESS_FEEDBACK_QUEUE,
  };
}

export function setPhoneVerificationData({ questionId, isValid, phoneNumber }) {
  return {
    type: SET_PHONE_VERIFICATION_DATA,
    payload: { questionId, isValid, phoneNumber },
  };
}

export function sendVerificationToken(verificationParams) {
  return {
    type: SEND_VERIFICATION_TOKEN,
    payload: { verificationParams },
  };
}

export function verifyPhoneNumber(verifyParams) {
  return {
    type: VERIFY_PHONE_NUMBER,
    payload: { verifyParams },
  };
}

export function toggleDropdown({ questionId }) {
  return {
    type: TOGGLE_DROPDOWN,
    payload: { questionId },
  };
}

export function clearResponsesOnState(payload) {
  return {
    type: CLEAR_RESPONSES_ON_STATE,
    payload,
  };
}

export function setFeedbackResponse(payload) {
  return {
    type: SET_FEEDBACK_RESPONSE,
    payload,
  };
}

export function setIsSubmitting(payload) {
  return {
    type: SET_IS_SUBMITTING,
    payload,
  };
}

export function setPreloadedResponses(payload) {
  return {
    type: SET_PRELOADED_RESPONSES,
    payload,
  };
}

export function setResponsesLoaded() {
  return {
    type: SET_RESPONSES_LOADED,
  };
}

export function setIsSubmitError(payload) {
  return {
    type: SET_IS_SUBMIT_ERROR,
    payload,
  };
}

export function setAnimationsEnabled(payload) {
  return {
    type: SET_ANIMATIONS_ENABLED,
    payload,
  };
}

export function setDefaultPlaceholder(payload) {
  return {
    type: SET_DEFAULT_PLACEHOLDER,
    payload,
  };
}

export function setFirstResponseTime(payload) {
  return {
    type: SET_FIRST_RESPONSE_TIME,
    payload,
  };
}

export function sendPostMessage(payload) {
  return {
    type: SEND_POST_MESSAGE,
    payload,
  };
}

export function sendChannelQuotaLog(payload) {
  return {
    type: SEND_CHANNEL_QUOTA_LOG,
    payload,
  };
}

export function setPreventMultipleFeedback(payload) {
  return {
    type: SET_PREVENT_MULTIPLE_FEEDBACK,
    payload,
  };
}
